import { Fragment, useEffect, useState } from "react"

/** Given an area and minimum number of elements,
 * get the row x col grid closest to producing perfect squares */
export function getOptimalGrid(width: number, height: number, numElements: number): [number, number] {
	let smallest = 0
	let bestrows = 0
	let bestcols = 0
	for (let rows = 1; rows < numElements; rows++) {
		let cols = Math.ceil(numElements / rows)
		let x = width / cols
		let y = height / rows

		// if neither are smaller, replace
		if (smallest <= x && smallest <= y) {
			smallest = Math.min(x, y)
			bestrows = rows
			bestcols = cols
		} else {
			break
		}
	}

	return [bestrows, bestcols]
}

export function getSize(img: HTMLImageElement) {
	const [ix, iy] = [img.naturalWidth, img.naturalHeight]
	const [wx, wy] = [document.documentElement.offsetWidth, document.documentElement.offsetHeight]
	const zoom = Math.min(wx / ix, wy / iy)
	return [zoom * ix, zoom * iy]
}

export const emphasize = (str: string, rx: RegExp) => {
	const nodes = []
	let i = 0
	let match: RegExpExecArray | null
	while ((match = rx.exec(str)) !== null) {
		nodes.push(<Fragment key={i * 2}>{str.substring(i, match.index)}</Fragment>)
		nodes.push(<em key={i * 2 + 1}>{match[1]}</em>)
		i = match.index + match[1].length
	}
	nodes.push(str.substring(i))
	return nodes
}

export function useGrid(count: number, container?: HTMLElement | null) {
	const [grid, setGrid] = useState({ rows: 1, cols: 1 })
	useEffect(() => {
		if (!container) return
		const resizeContainers = () => {
			const [rows, cols] = getOptimalGrid(container.offsetWidth, container.offsetHeight, count)
			setGrid({ rows, cols })
		}
		resizeContainers()
		window.addEventListener("resize", resizeContainers)
		return () => window.removeEventListener("resize", resizeContainers)
	}, [count, container])
	return grid
}
