import { Fragment } from "react"
import { format as formatDate } from "date-fns"
import { Temperature } from "./Temperature"

// const rxWeatherTypes = /\b(snowfall|blowing snow|snow|windy|ice pellets|ice pellet|ice|wet flurries|flurries|blizzard|freezing rain|rain showers|rain|showers|fog patches|fog|hail)\b/gi
const evenStyle = { textAlign: "right" } as const
const oddStyle = { textAlign: "left", width: "1em", borderRight: "1px solid #bbb" } as const

const substitutions = (
	[
		[/a mix of sun and clouds?/, "⛅"],
		[/sun(ny)?/, "☀️"],
		[/cloudy?/, "☁️"],
		[/(rainy?|(rain ?)?showers)/, "🌧️"],
		// [/windy?/, "🌬️"],
		[/windy?/, "💨"],
		[/clear(ing)?/, "🌙"],
		[/snow|flurries/, "❄️"],
		// turn /abc/ into /\babc\b/gi
	] as [RegExp, string][]
).map(([rx, sub]) => [new RegExp("\\b" + rx.source + "\\b", "gi"), sub]) as [RegExp, string][]

export const getEmojis = (str: string) =>
	substitutions.reduce(([s, src], [rx, sub]) => [rx.test(src) ? s + sub : s, src.replace(rx, sub)], ["", str])[0]

export type Forecast = {
	date: string
	period: string
	temperature: {
		value: number
		apex: string
	}
	description: string
}

type WeatherForecastProps = {
	forecast?: Forecast[]
}
export const WeatherForecastBar = ({ forecast }: WeatherForecastProps) => {
	const days = forecast?.reduce((days, { date }) => {
		const str = formatDate(new Date(date), "EE")
		!days.includes(str) && days.push(str)
		return days
	}, [] as string[])

	if (!forecast || !days) return null

	return (
		<table style={{ borderCollapse: "collapse", border: "1px solid #ccc" }}>
			<thead style={{ backgroundColor: "#ccc" }}>
				<tr>
					<th></th>
					{days.map(d => (
						<th key={d} colSpan={2}>
							{d}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				<tr>
					<th style={{ textAlign: "right", borderRight: "1px solid #bbb" }}>Day</th>
					{forecast[0].period === "night" && (
						<>
							<td style={evenStyle}></td>
							<td style={oddStyle}></td>
						</>
					)}
					{forecast
						.filter(f => f.period === "day")
						.map(({ date, temperature, description }) => (
							<Fragment key={date}>
								<td style={evenStyle}>
									<Temperature temp={temperature.value} />
								</td>
								<td style={oddStyle}>
									{/* <WeatherDescription description={description} /> */}
									{getEmojis(description)}
								</td>
							</Fragment>
						))}
				</tr>
				<tr style={{ borderTop: "1px solid #bbb" }}>
					<th style={{ textAlign: "right", borderRight: "1px solid #bbb" }}>Night</th>
					{forecast
						.filter(f => f.period === "night")
						.map(({ date, temperature, description }) => (
							<Fragment key={date}>
								<td style={evenStyle}>
									<Temperature temp={temperature.value} />
								</td>
								<td style={oddStyle}>
									{/* <WeatherDescription description={description} /> */}
									{getEmojis(description)}
								</td>
							</Fragment>
						))}
					{forecast[forecast.length - 1].period !== "night" && (
						<>
							<td style={evenStyle}></td>
							<td style={oddStyle}></td>
						</>
					)}
				</tr>
			</tbody>
		</table>
	)
}
