import { SparkLine } from "./SparkLine"
import { useTheme } from "../Theme"
import { Reading } from "./StationTile"

type Props = {
	readings: Reading[]
}

export const OtherConditions = ({ readings }: Props) => {
	const { small } = useTheme()
	const current = readings[readings.length - 1] as typeof readings[number] | undefined
	if (!current?.humidity) return null

	const history = readings.map(d => ({ x: d.reading_time, y: d.humidity! }))

	return (
		<div
			style={{
				marginLeft: small ? "13px" : undefined,
				position: small ? "absolute" : undefined,
				textAlign: small ? "left" : "center",
			}}
		>
			{small ? (
				<span style={{ display: "auto", verticalAlign: "middle" }}>{current.humidity + "% RH"}</span>
			) : (
				<>
					<span style={{ display: "inline-block", width: "64px" }}>
						<SparkLine points={history} />
					</span>
					{current.humidity?.toFixed(0) + "% RH"}
				</>
			)}
			{current.wind_mean !== null && ` / ${Math.round(current.wind_mean!)} km/h${small ? "" : " winds"}`}
		</div>
	)
}
