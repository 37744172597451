import { ChartOptions } from "chart.js"

export const getOptions = (): ChartOptions<"line"> => ({
	elements: {
		line: {
			tension: 0,
			borderWidth: 4,
		},
		point: {
			radius: 0,
			hitRadius: 5,
			hoverRadius: 0,
		},
	},
	animation: { duration: 0 },
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		x: {
			ticks: {
				font: { size: 20 },
				source: "auto",
			},
			title: {
				text: "Hours",
				display: false,
			},
			type: "timeseries",
			time: {
				displayFormats: { hour: "ha" },
				unit: "hour",
				stepSize: 6,
			},
			// distribution: "linear",
		},
		temperature: {
			axis: "y",
			position: "left",
			type: "linear",
			grid: { lineWidth: x => (x.tick.value ? 1 : 3) },
			ticks: {
				font: { size: 20 },
				stepSize: 5,
			},
			beginAtZero: false,
			min: -15,
			max: 10,
		},
		accumulation: {
			axis: "y",
			position: "right",
			type: "linear",
			ticks: {
				font: { size: 20 },
				stepSize: 5,
			},
			grid: { display: false },
			beginAtZero: true,
			min: 0,
			max: 20,
		},
		y2: { display: false },
		y3: { display: false },
		y4: { display: false },
	},
	plugins: {
		legend: { display: false },
		title: {
			display: true,
			font: { size: 24 },
			color: "black",
		},
	},
})
