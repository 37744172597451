import { useState } from "react"
import { useParams } from "react-router-dom"
import { Camera } from "./Camera"
import { useGrid } from "../utils"
import { CameraZoom } from "./CameraZoom"
import { useTheme } from "../Theme"
import AvailableCameras from "./cameras.json"

const CAMERA_UPDATE_INTERVAL = 1 * 60 * 1000

export const CameraGrid = () => {
	const { region } = useParams()
	const [container, setContainer] = useState<HTMLElement>()
	const [zoomUrl, setZoomUrl] = useState<string>()
	const { small } = useTheme()
	const cameras = AvailableCameras[region as keyof typeof AvailableCameras]
	const { rows, cols } = useGrid(Object.keys(cameras).length, container)

	return (
		<>
			{zoomUrl !== undefined && <CameraZoom url={zoomUrl} onClick={() => setZoomUrl(undefined)} />}
			<ol
				ref={c => c && !container && setContainer(c)}
				style={{
					margin: 0,
					padding: 0,
					listStyle: "none",
					display: small ? "block" : "grid",
					height: small ? "auto" : "100vh",
					width: small ? "100%" : "inherit",
					gridTemplateRows: Array.from({ length: rows }, () => "1fr").join(" "),
					gridTemplateColumns: Array.from({ length: cols }, () => "1fr").join(" "),
				}}
			>
				{Object.keys(cameras).map(name => {
					const url = getCameraUrl(cameras[name as keyof typeof cameras])
					return (
						<li
							key={name}
							style={{
								backgroundColor: "#bbb",
								fontFamily: "Calibri, 'Trebuchet MS', sans-serif",
								fontSize: "20pt",
								fontWeight: "bold",
								color: "#eee",
								textShadow: "0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black",
							}}
						>
							<Camera
								name={name}
								url={url}
								interval={CAMERA_UPDATE_INTERVAL}
								zoomAction={small ? undefined : setZoomUrl}
							/>
						</li>
					)
				})}
			</ol>
		</>
	)
}

const getCameraUrl = (val?: number | string) =>
	`https://hmm.eag.tools/highwaycams/${typeof val === "string" ? "" : "drivebc/"}${val}`
