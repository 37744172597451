import { useState } from "react"
import { useParams } from "react-router-dom"
import { addDays, startOfHour } from "date-fns"
import { useRwisQuery } from "../graphql"
import { useGrid } from "../utils"
import { StationTile } from "./StationTile"
import { useTheme } from "../Theme"
import { RwisLegend } from "./RwisLegend"

const rwisRegions = {
	terrace: [52091, 52092, 52093, 52326, 52391, 52401, 52522, 52591],
	fraser: [14091, 15071, 15092, 15122, 15322, 25221, 25225, 25229],
	robson: [41091, 46094, 46127, 46191, 46182, 42064],
}

// TODO fix print view of RWIS
export const RwisApp = () => {
	const { region } = useParams()
	const rwisStations = rwisRegions[region === "terrace" ? "terrace" : region === "chilliwack" ? "fraser" : "robson"]
	const { error, data } = useRwisQuery({
		variables: {
			rwisStations,
			start: addDays(startOfHour(new Date()), -1),
			end: addDays(startOfHour(new Date()), 1),
		},
		pollInterval: 15 * 60 * 1000,
	})

	const [tiles, setTiles] = useState<HTMLElement>()
	const { rows, cols } = useGrid(rwisStations.length, tiles)
	const { small } = useTheme()

	return (
		<article
			style={{
				fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
				height: small ? undefined : "100vh",
				overflow: small ? undefined : "hidden",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<RwisLegend />
			<section
				ref={t => t && !tiles && setTiles(t)}
				style={{
					display: small ? "block" : "grid",
					height: small ? "auto" : "100vh",
					width: small ? "100%" : "inherit",
					flexGrow: 1,
					gridGap: "1px",
					backgroundColor: "black",
					gridTemplateRows: Array.from({ length: rows }, () => "1fr").join(" "),
					gridTemplateColumns: Array.from({ length: cols }, () => "1fr").join(" "),
				}}
			>
				{data?.rwis_stations.map(s => <StationTile station={s} key={s.station_name} />) ?? error?.message ?? null}
			</section>
		</article>
	)
}
