import React from "react"
import ReactDOM from "react-dom"
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"
import { BrowserRouter } from "react-router-dom"
import { Theme } from "./Theme"
import * as serviceWorker from "./serviceWorker"
import { App } from "./App"
import "./index.css"

const client = new ApolloClient({
	uri: "https://hmm.eag.tools/graphql",
	cache: new InMemoryCache(),
})

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ApolloProvider client={client}>
				<Theme>
					<App />
				</Theme>
			</ApolloProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
