import { useRef } from "react"
import { useTheme } from "./Theme"
import { DriveBcEventLog } from "./DriveBcEvents/DriveBcEventLog"
import { Weather } from "./Weather/Weather"
import { CameraGrid } from "./HighwayCams/CameraGrid"

export const Dashboard = () => {
	const weatherEvents = useRef<HTMLElement>(null)
	const eventContainer = useRef<HTMLTableElement>(null)
	const { small, print } = useTheme()

	const makeRoom = (reset = false) => {
		const we = weatherEvents.current!
		// reset to default size on request
		if (reset) {
			we.style.flexBasis = "800px"
			return true
		}
		// don't resize beyond width of window
		if (we.offsetWidth * 1.2 >= window.innerWidth) return false
		we.style.flexBasis = we.offsetWidth * 1.2 + "px"
		return true
	}

	return (
		<main style={{ height: small ? "auto" : "100vh", display: small ? "block" : "flex" }}>
			<article
				ref={weatherEvents}
				style={{ flex: "0 0 800px", width: small ? "100%" : undefined, height: small ? "auto" : undefined }}
			>
				<Weather />
				<DriveBcEventLog makeRoom={makeRoom} eventContainer={eventContainer} />
			</article>
			<section style={{ flex: "1 1 auto", display: print ? "none" : "block" }}>
				<CameraGrid />
			</section>
		</main>
	)
}
