import { PropsWithChildren, createContext, useMemo, useContext } from "react"
import { useMediaQuery } from "@mui/material"

export type ThemeProps = {
	small: boolean
	print: boolean
}

const ThemeContext = createContext<ThemeProps>({ small: false, print: false })
export const useTheme = () => useContext(ThemeContext)

export const Theme = ({ children }: PropsWithChildren<{}>) => {
	const small = useMediaQuery("(max-width: 1152px)")
	const print = useMediaQuery("print")
	const value = useMemo(() => ({ small, print }), [small, print])
	return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
