import { Link, useParams } from "react-router-dom"
import { useTheme } from "../Theme"

export const RwisLegend = () => {
	const { region } = useParams()
	const { print } = useTheme()
	const fontSize = print ? "15pt" : undefined
	return (
		<section
			style={{ flex: "none", position: print ? "fixed" : undefined, left: print ? "0.13in" : undefined }}
			className="legend"
		>
			<span style={{ fontSize, backgroundColor: "rgb(26, 84, 190)" }}>Air Temp</span>
			<span style={{ fontSize, backgroundColor: "rgb(224,51,7)" }}>Pavement Temp</span>
			<span style={{ fontSize, backgroundColor: "grey" }}>Dew Point</span>
			<span style={{ fontSize, backgroundColor: "rgb(89, 147, 255)" }}>Forecasted Surface Temp</span>
			<span style={{ fontSize, backgroundColor: "rgba(255, 196, 0, 1)" }}>Precip</span>
			<span style={{ fontSize, backgroundColor: "rgb(0,127,0)" }}>New Snow</span>
			<Link to={`/dashboard/${region}`} style={{ backgroundColor: "#333", display: print ? "none" : undefined }}>
				Back to Dashboard
			</Link>
		</section>
	)
}
