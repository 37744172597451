import { Skeleton } from "@mui/material"
import { Temperature } from "./Temperature"
import { WeatherQuery } from "../graphql"
import { WeatherRow } from "./WeatherRow"

const TendencyStr = {
	RISING: "↑",
	FALLING: "↓",
	STEADY: "=",
} as const
type Props = {
	current?: WeatherQuery["weather"][number]["current"]
}
export const WeatherCurrent = ({ current }: Props) => {
	const { temperature, condition, wind, humidity, pressure, dewpoint, visibility } = current ?? {}
	return (
		<section>
			<p style={{ fontSize: "1.3em", margin: "0.5em 0" }}>
				{temperature === undefined || temperature === null ? (
					<Skeleton />
				) : (
					<>
						<Temperature temp={temperature} symbol /> {condition}
					</>
				)}
			</p>
			<table style={{ margin: "auto" }}>
				<tbody>
					{current ? (
						<>
							{wind && (
								<WeatherRow title="Wind">
									{wind.speed} kph {wind.direction}
									{wind.gust && <span> w/ {wind.gust} kph gusts</span>}
								</WeatherRow>
							)}
							{humidity !== null && <WeatherRow title="Humidity">{Math.round(humidity! * 100)}%</WeatherRow>}
							{pressure && (
								<WeatherRow title="Pressure">
									{pressure.strength} kPa
									{TendencyStr[pressure.tendency as keyof typeof TendencyStr]}
								</WeatherRow>
							)}
							{dewpoint !== null && <WeatherRow title="Dewpoint">{dewpoint}&deg;</WeatherRow>}
							{visibility !== null && <WeatherRow title="Visibility">{visibility} km</WeatherRow>}
						</>
					) : (
						<tr>
							<td>
								<Skeleton animation="wave" width={200} />
								<Skeleton animation="wave" width={200} />
								<Skeleton animation="wave" width={200} />
								<Skeleton animation="wave" width={200} />
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</section>
	)
}
