import { useMemo } from "react"
import { ChartOptions, Point } from "chart.js"
import { Line } from "react-chartjs-2"

const getDefaultOptions = () =>
	({
		plugins: {
			tooltip: { enabled: false },
			legend: { display: false },
		},
		animation: { duration: 0 },
		elements: {
			point: { radius: 0 },
			line: {
				fill: false,
				borderWidth: 2,
				borderColor: "black",
			},
		},
		scales: {
			x: {
				display: false,
				grid: { display: false },
				// type: "time",
				type: "timeseries",
			},
			y: {
				display: false,
				grid: {
					display: false,
					drawBorder: true,
				},
				min: 50,
				max: 102,
			},
		},
	} as ChartOptions<"line">)

type Props = {
	points: Point[]
	width?: number
	height?: number
}
export const SparkLine = ({ points, width = 65, height = 19 }: Props) => {
	const options = useMemo(() => {
		const options = getDefaultOptions()
		options.scales!.y!.min = Math.min(...points.map(i => i.y as number)) - 2
		return options
	}, [points])

	return <Line data={{ datasets: [{ data: points }] }} {...{ width, height, options }} />
}
