import { useMemo } from "react"
import { Forecast, Reading } from "./StationTile"

export const useRange = (readings: Reading[], forecast?: Forecast) => {
	// Create a sliding chart scale with the rounded bottom 20 degrees of the data ranges
	return useMemo(() => {
		const dataPoints = [
			...readings.flatMap(i => [i.air_temp, i.surface_temp, i.dew_point, i.snow_new, i.precip_new]),
			...(forecast?.targets.map(i => i.surface_temp) ?? []),
		].filter(i => i !== undefined && i !== null) as number[]
		const min = Math.floor(Math.min(...dataPoints) / 5) * 5
		return [min, min + 20]
	}, [readings, forecast])
}
