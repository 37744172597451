type Props = {
	warnings?: string[]
}

export const WeatherWarnings = ({ warnings }: Props) => {
	if (!warnings || warnings.length === 0) return null
	return (
		<section
			style={{
				fontSize: "larger",
				fontWeight: "bold",
				padding: "0.5em 1em",
				borderWidth: "3px 0",
				borderStyle: "solid",
				borderColor: "black",
				textAlign: "center",
				backgroundColor: "yellow",
			}}
		>
			{warnings.map((warning, i) => (
				<span key={"warning-" + i}>
					{warning}
					<br />
				</span>
			))}
		</section>
	)
}
