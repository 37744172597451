import { useMemo } from "react"
import { Line } from "react-chartjs-2"
import "chart.js/auto"
import "chartjs-adapter-date-fns"
import { getOptions } from "./RwisChartOptions"
import { useDatasets } from "./useDatasets"
import { useRange } from "./useRange"
import { useForecast, Reading } from "./StationTile"

const useTileOptions = (stationName: string, range: number[]) => {
	return useMemo(() => {
		const options = getOptions()
		options.plugins!.title!.text = stationName[0].toUpperCase() + stationName.substring(1)
		const scale = options.scales!.temperature!
		const [min, max] = range || [0, 20]
		scale.min = min
		scale.max = max
		return options
		// }, [stationName, errors, range])
	}, [stationName, range])
}

type Props = {
	stationName: string
	readings: Reading[]
	forecast: ReturnType<typeof useForecast>
}
export const RwisChart = ({ stationName, readings, forecast }: Props) => {
	const { errors, datasets } = useDatasets(readings, stationName, forecast)
	const range = useRange(readings, forecast)
	const tileOptions = useTileOptions(stationName, range)
	return (
		<div style={{ position: "relative", height: "100%" }}>
			{datasets?.length ? (
				<Line data={{ datasets }} options={tileOptions} />
			) : (
				<p style={{ fontSize: "larger", fontWeight: "bold" }}>Data not available for {stationName}</p>
			)}
			{errors.size ? (
				<p style={{ position: "absolute", top: "2em", width: "100%", textAlign: "center", fontSize: "1.5em" }}>
					Missing {Array.from(errors).join(", ")} data
				</p>
			) : null}
		</div>
	)
}
