import { useMemo } from "react"
import { emphasize } from "../utils"

const rxWeatherTypes =
	/\b(snowfall|blowing snow|snow|windy|ice pellets|ice pellet|ice|wet flurries|flurries|blizzard|freezing rain|rain showers|rain|showers|fog patches|fog|hail)\b/gi

const substitutions = (
	[
		[/a mix of sun and clouds?/, "⛅"],
		[/sun(ny)?/, "☀️"],
		[/cloudy?/, "☁️"],
		// [/(rainy?|(rain ?)?showers)/, "🌧️"],
		[/((rain ?)?showers)/, "💧"],
		// [/((rain ?)?showers)/, "🌧️"],
		[/(rainy?)/, "🌧️"],
		// [/windy?/, "🌬️"],
		// [/windy|gusty winds?/, "💨"],
		[/clear(ing)?/, "🌙"],
		[/w/, ""],
		[/snow/, "❄️"],
		// [/snow/,"🌨️"],
		// [/flurries/,"🌨️"],
		// [/flurries/, "❅"],
		// turn /abc/ into /\babc\b/gi
	] as [RegExp, string][]
).map(([rx, sub]) => [new RegExp("\\b" + rx.source + "\\b", "gi"), sub]) as [RegExp, string][]

export const substitute = (str: string) => substitutions.reduce((s, [rx, sub]) => s.replace(rx, sub), str)

export type Props = {
	description: string
}
export const WeatherDescription = ({ description }: Props) => {
	const cleanDescription = useMemo(() => {
		const shortDesc = description // substitute(description) // avoid for potential liability reasons
		return emphasize(shortDesc, rxWeatherTypes)
	}, [description])
	return <>{cleanDescription}</>
}
