import { useMemo } from "react"
import clsx from "clsx"
import { formatDistanceToNow } from "date-fns"
import { emphasize } from "../utils"
import { useTheme } from "../Theme"
import eventTypes from "./driveBc-eventTypes.json"
import { DrivebcQuery } from "../graphql"
import "./event_type.css"

const rxDriveBcEventTypes = RegExp("\\b(" + eventTypes.join("|") + ")\\b", "gi")

type DriveBcEventType = DrivebcQuery["drivebcEvents"][number]

type Props = {
	event: DriveBcEventType
}
export const DriveBcEvent = ({ event }: Props) => {
	const { eventType, severity, updated, description } = event
	const { small } = useTheme()

	const cleanDesc = useMemo(() => {
		let abbr = description
			.replace(/,? (north|south|east|west)bound/g, (_, g1) => ` ${g1[0].toUpperCase()}B`)
			.replace(/(in )?both directions/g, "both dirs")
			.replace(/ ?P[SD]T\b/g, "")
			.replace(" For more information visit GOV.BC.Ca/COVID-19.", "")
		return emphasize(abbr, rxDriveBcEventTypes)
	}, [description])

	const severe = severity === "MODERATE" || severity === "MAJOR"
	return (
		<tr className={clsx(eventType, { SEVERE: severe }, "drivebc-event")}>
			<td style={{ width: small ? "auto" : "6em", display: small ? "block" : "table-cell" }}>
				{formatDistanceToNow(new Date(updated), { addSuffix: false })}
			</td>
			<td style={{ fontFamily: "Calibri, Verdana, sans-serif", display: small ? "block" : "table-cell" }}>
				{cleanDesc}
			</td>
		</tr>
	)
}
