import { useEffect, useRef, useState } from "react"
import { useTheme } from "../Theme"

type CameraProps = {
	name: string
	url: string
	interval: number
	zoomAction?: (url: string) => void
}

export const Camera = ({ name, url, interval, zoomAction }: CameraProps) => {
	const img = useRef<HTMLImageElement>(null)
	const [expanded, setExpanded] = useState(false)
	const { small } = useTheme()

	const [fullUrl, setFullUrl] = useState(url)

	useEffect(() => {
		const updateImage = async () => {
			const fullUrl = `${url}?${new Date().getTime()}`
			try {
				const response = await fetch(fullUrl)
				if (response.status === 200) {
					setFullUrl(fullUrl)
				} else if (response.status === 304) {
					// don't update unmodified image
				} else {
					console.error("Error retrieving updated image at", fullUrl, response)
				}
			} catch (response) {
				console.error("Error retrieving updated image at", fullUrl, response)
			}
		}

		updateImage()
		const i = setInterval(updateImage, interval)
		return () => clearInterval(i)
	}, [interval, url])

	const toggleZoom = () => {
		if (zoomAction) return zoomAction(url)
		setExpanded(!expanded)
	}
	return (
		<div onClick={toggleZoom} style={{ position: "relative", height: "100%" }}>
			<img
				src={fullUrl}
				onError={() => (img.current!.style.display = "none")}
				onLoad={() => (img.current!.style.display = "block")}
				alt={name}
				ref={img}
				style={{
					objectFit: "cover",
					display: "block",
					width: "100%",
					transition: small ? "height 0.4s" : "",
					height: small
						? expanded
							? Math.floor((img.current!.naturalHeight / img.current!.naturalWidth) * img.current!.offsetWidth)
							: "4em"
						: "100%",
				}}
			/>
			<p
				style={{
					position: "absolute",
					margin: "0 auto",
					top: 0,
					width: "100%",
				}}
			>
				{name}
			</p>
		</div>
	)
}
