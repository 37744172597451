import { format as formatDate } from "date-fns"
import { Skeleton } from "@mui/material"
import { WeatherDescription } from "./WeatherDescription"
import { Temperature } from "./Temperature"
import { useTheme } from "../Theme"

export type Forecast = {
	date: string
	period: string
	temperature: {
		value: number
		apex: string
	}
	description: string
}

const ApexStr = {
	HIGH: "H",
	LOW: "L",
	STEADY: "=",
} as const

type Props = {
	forecast?: Forecast[]
}
export const WeatherForecast = ({ forecast }: Props) => {
	const { print } = useTheme()
	return (
		<table
			style={{
				display: print ? "block" : undefined,
				paddingTop: print ? "1em" : undefined,
				margin: "1em auto",
				fontSize: "large",
				borderSpacing: 0,
			}}
			className="forecast"
		>
			<thead>
				{forecast ? (
					forecast.map(({ date, period, temperature, description }) => (
						<tr key={"forecast-" + date + period} className={`forecast-${period}`}>
							<td>{formatDate(new Date(date), "EE")}</td>
							<td>{ApexStr[temperature.apex as keyof typeof ApexStr]}</td>
							<td style={{ textAlign: "right" }}>
								<Temperature temp={temperature.value} />
							</td>
							<td style={{ textAlign: "left" }}>
								<WeatherDescription description={description} />
							</td>
						</tr>
					))
				) : (
					<tr>
						<td>
							<Skeleton animation="wave" width={200} />
							<Skeleton animation="wave" width={200} />
							<Skeleton animation="wave" width={200} />
							<Skeleton animation="wave" width={200} />
						</td>
					</tr>
				)}
			</thead>
		</table>
	)
}
