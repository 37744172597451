import { useMemo } from "react"
import { RwisQuery } from "../graphql"
import { useTheme } from "../Theme"
import { WrittenForecast } from "./WrittenForecast"
import { OtherConditions } from "./OtherConditions"
import { RwisChart } from "./RwisChart"
import { addDays } from "date-fns"

export type Station = RwisQuery["rwis_stations"][number]
export type Reading = Station["readings"][number]
export type Forecast = Station["latestForecast"]

// const canvasHeight = (height ? height * 2/3 : width * 2/3)
// const canvasHeight = height ? (height * 3) / 5 : (width * 2) / 3

export const useForecast = (forecast?: Forecast | null) => {
	return useMemo(() => {
		// drop forecast if it doesn't exist or is old
		if (!forecast || Date.now() > addDays(new Date(forecast.issue_time), 1).getTime()) return
		const targets = forecast?.targets.map(({ target_time, ...i }) => ({ ...i, target_time: new Date(target_time) }))
		return { ...forecast, targets } as Forecast
	}, [forecast])
}

type Props = {
	station: Station
}
export const StationTile = ({ station }: Props) => {
	const { latestForecast, readings, station_name } = station
	const { small, print } = useTheme()

	const forecast = useForecast(latestForecast)

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				height: small ? "15em" : "100%",

				// TODO use grid or table with border-collapse
				backgroundColor: "white",
				width: print ? "100%" : undefined,
				pageBreakAfter: print ? "always" : undefined,
				marginTop: print ? "0.5in" : undefined,
				borderWidth: print ? 0 : "1px",
				overflow: "hidden",
			}}
		>
			<div style={{ flex: "1 1 auto" }}>
				<RwisChart stationName={station_name} readings={readings} forecast={forecast} />
			</div>
			<OtherConditions readings={readings} />
			{forecast && <WrittenForecast forecast={forecast} />}
		</div>
	)
}
