import { ChartDataset } from "chart.js"
import { Reading } from "./StationTile"

export const getSeries = (
	label: string,
	fill: boolean,
	yAxisID: string,
	borderColor: string,
	readings: Reading[],
	key: keyof Reading,
	backgroundColor?: string
) => {
	if (!readings.length) return
	return {
		label,
		fill,
		yAxisID,
		borderColor,
		backgroundColor,
		data: readings.map(i => ({ x: i.reading_time, y: i[key] as number })),
	} as ChartDataset<"line">
}
