type CameraZoomProps = {
	url: string
	onClick: () => void
}

export const CameraZoom: React.FC<CameraZoomProps> = ({ url, onClick }) => {
	return (
		<img
			src={url}
			alt="Zoom Window"
			onClick={onClick}
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				height: "100vh",
				width: "100vw",
				objectFit: "contain",
				zIndex: 100,
			}}
		/>
	)
}
