export type Props = {
	temp: number
	symbol?: boolean
	units?: boolean
	cold?: number
	hot?: number
}
export const Temperature = ({ temp, symbol = false, units = false, hot = 22, cold = 3 }: Props) => {
	return (
		<span style={{ color: temp <= cold ? "blue" : temp >= hot ? "rgb(170,0,0)" : "inherit" }}>
			{temp}
			{symbol && "°"}
			{units && "C"}
		</span>
	)
}
