import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import { Dashboard } from "./Dashboard"
import { RwisApp } from "./Rwis/RwisApp"
import "./App.css"

const AVAILABLE_REGIONS = ["chilliwack", "terrace", "valemount"] as const
const titleRx = /^\/.*\/([^/]+)/
const useTitleHandler = () => {
	const location = useLocation().pathname
	const match = titleRx.exec(location)?.[1]
	const region = match || localStorage.region || AVAILABLE_REGIONS[0]
	localStorage.region = region
	const regionTitle = region.charAt(0).toUpperCase() + region.substr(1)
	document.title = `EAM Condition Monitor | ${regionTitle}`
	return region
}

export const App = () => {
	const region = useTitleHandler()
	return (
		<Routes>
			<Route path="/dashboard/:region" element={<Dashboard />} />
			<Route path="/rwis/:region" element={<RwisApp />} />
			<Route path="/" element={<Navigate to={`/dashboard/${region}`} />} />
		</Routes>
	)
}
