import { useMemo } from "react"
import { addMinutes } from "date-fns"
import { ChartDataset } from "chart.js"
import { Forecast, Reading } from "./StationTile"
import { getSeries } from "./getSeries"

export const useDatasets = (readings: Reading[], station_name: string, forecast?: Forecast) =>
	useMemo(() => {
		const errors = new Set<string>()
		// since it's normal for them to have missing data at this point
		// ignore entries missing information in the last 10 minutes
		const cutoffTime = addMinutes(new Date(), -10)
		const expectedData = readings.filter(i => new Date(i.reading_time) < cutoffTime)
		const checkProblem = (key: keyof Reading, desc: string) =>
			expectedData.find(i => i[key] === undefined) !== undefined && errors.add(desc)

		checkProblem("air_temp", "air temp")
		checkProblem("surface_temp", "pavement temp")
		checkProblem("dew_point", "dewpoint")
		checkProblem("snow_new", "new snow")
		checkProblem("precip_new", "precip")
		if (station_name === "Shames") {
			errors.delete("pavement temp")
			errors.delete("dewpoint")
		}

		const forecastTemp = forecast?.targets.map(i => ({ x: i.target_time, y: i.surface_temp }))
		const datasets = [
			getSeries("Air Temp", false, "temperature", "rgb(26,84,190)", readings, "air_temp"),
			getSeries("Pavement Temp", false, "temperature", "rgb(244,51,7)", readings, "surface_temp"),
			getSeries("Dew Point", true, "temperature", "rgba(0,0,0,0)", readings, "dew_point", "rgba(0,0,0,0.15)"),
			forecastTemp && {
				label: "Forecasted Air Temp",
				fill: false,
				yAxisID: "temperature",
				borderColor: "rgb(89, 147, 255)",
				borderDash: [8, 5],
				data: forecastTemp,
			},
			getSeries("New Snow", false, "accumulation", "rgb(0,127,0)", readings, "snow_new"),
			getSeries("Precip", false, "accumulation", "rgba(255,196,0,1)", readings, "precip_new"),
		].filter(i => i!) as ChartDataset<"line">[]
		return {
			errors,
			datasets,
		}
	}, [readings, forecast, station_name])
