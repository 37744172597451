import { useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { WeatherCurrent } from "./WeatherCurrent"
import { WeatherForecast } from "./WeatherForecast"
import { WeatherForecastBar } from "./WeatherForecastBar"
import { WeatherWarnings } from "./WeatherWarnings"
import { useTheme } from "../Theme"
import { useWeatherQuery } from "../graphql"

const AVAILABLE_LOCATIONS = ["Terrace", "Chilliwack", "Valemount"]

const controlStyle = {
	fontSize: "x-large",
	fontWeight: "bold",
	cursor: "pointer",
	border: "1px solid #bbb",
	height: "1.4em",
} as const

export const Weather = () => {
	const { region } = useParams() as { region: string }
	const station = region === "robson" ? "Valemount" : region[0].toUpperCase() + region.substr(1)
	const { error, data } = useWeatherQuery({ variables: { station }, pollInterval: 60 * 5 * 1000 })
	const [demo, setDemo] = useState(false)
	const { small, print } = useTheme()
	const navigate = useNavigate()
	const weather = data?.weather[0]
	return (
		<section style={{ fontSize: "x-large", margin: 0 }}>
			{error ? (
				<p>
					Error loading Environment Canada weather data:
					<br />
					<pre>{JSON.stringify(error, null, 2)}</pre>
				</p>
			) : (
				<>
					<WeatherWarnings warnings={weather?.warnings} />

					<div style={{ display: small ? "block" : "flex" }}>
						<section
							style={{
								flex: "0 0 15em",
								display: print ? "block" : undefined,
								width: print ? "auto" : undefined,
							}}
						>
							<div
								style={{
									marginTop: "0.5em",
								}}
							>
								<button
									onClick={() => navigate(`/rwis/${region}`)}
									style={{
										...controlStyle,
										borderRadius: "0.3em 0 0 0.3em",
										borderRight: 0,
										backgroundColor: "#ccc",
									}}
								>
									RWIS
								</button>
								<select
									name="region"
									value={station}
									onChange={e => navigate(`/dashboard/${e.target.value.toLowerCase()}`)}
									style={{ ...controlStyle, borderRadius: "0 0.3em 0.3em 0" }}
								>
									{AVAILABLE_LOCATIONS.map(l => (
										<option key={l} value={l}>
											{l}
										</option>
									))}
								</select>
							</div>
							<WeatherCurrent current={weather?.current} />
							<button onClick={() => setDemo(!demo)}>{demo ? "Hide" : "Show"} Alt Forecast</button>
						</section>
						<WeatherForecast forecast={weather?.forecasts} />
					</div>
					{demo && (
						<div style={{ textAlign: "center", display: "inline-block" }}>
							<WeatherForecastBar forecast={weather?.forecasts} />
						</div>
					)}
				</>
			)}{" "}
		</section>
	)
}
