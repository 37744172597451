import { PropsWithChildren } from "react"

export type Props = {
	title: string
}
export const WeatherRow = ({ title, children }: PropsWithChildren<Props>) => {
	return (
		<tr>
			<th style={{ textAlign: "right", paddingRight: "1em" }}>{title}</th>
			<td style={{ textAlign: "left" }}>{children}</td>
		</tr>
	)
}
