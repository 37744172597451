import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Skeleton } from "@mui/material"
import { useDrivebcQuery } from "../graphql"
import { DriveBcEvent } from "./DriveBcEvent"
import { useTheme } from "../Theme"

type Props = {
	makeRoom: (reset?: boolean) => boolean
	eventContainer: React.RefObject<HTMLTableElement>
}
export const DriveBcEventLog = ({ makeRoom, eventContainer }: Props) => {
	const { region } = useParams() as { region: string }
	const { small, print } = useTheme()
	const area = ["terrace", "valemount"].includes(region) ? region : "fraser"
	const { error, data } = useDrivebcQuery({
		variables: { area },
		pollInterval: 60 * 5 * 1000,
	})
	const events = data?.drivebcEvents

	useEffect(() => {
		if (small) return
		const container = eventContainer.current
		if (!container) return
		const isTooBig = () => container.offsetTop + container.offsetHeight > window.innerHeight

		// size font to fit window
		const sizeFont = (minSize = 0.8) => {
			let eventFontSize = 1.5
			container.style.fontSize = eventFontSize + "em"
			while (isTooBig() && eventFontSize >= minSize) {
				container.style.fontSize = eventFontSize + "em"
				eventFontSize *= 0.9
			}
		}

		// reset width if it already fits
		isTooBig() || makeRoom(true)
		sizeFont()
		isTooBig() && makeRoom() && sizeFont()
	}, [events, makeRoom, small, eventContainer])

	return (
		<table
			ref={eventContainer}
			style={{
				listStyle: "none",
				margin: "1em 0 0 0",
				padding: 0,
				width: "100%",
				fontSize: small ? "1em" : "1.5em",
				borderCollapse: "collapse",
				pageBreakBefore: print ? "always" : "auto",
			}}
		>
			<tbody>
				{error ? (
					<tr>
						<td>
							<p>Error loading DriveBC event data</p>
						</td>
					</tr>
				) : !events ? (
					<tr>
						<td>
							<Skeleton animation="wave" />
							<Skeleton animation="wave" />
							<Skeleton animation="wave" />
							<Skeleton animation="wave" />
						</td>
					</tr>
				) : (
					events.map(e => <DriveBcEvent key={"event-" + e.description} event={e} />)
				)}
			</tbody>
		</table>
	)
}
