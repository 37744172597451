import { useState, useEffect } from "react"
import { format as formatDate } from "date-fns"
import { Forecast } from "./StationTile"

const useDimensions = () => {
	const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])
	useEffect(() => {
		const redim = () => setDimensions([window.innerWidth, window.innerHeight])
		window.addEventListener("resize", redim)
		return () => window.removeEventListener("resize", redim)
	}, [])

	return dimensions
}

type Props = {
	forecast: NonNullable<Forecast>
}

export const WrittenForecast = ({ forecast }: Props) => {
	const [forecastContainer, setForecastContainer] = useState<HTMLDivElement | null>()
	const dimensions = useDimensions()

	useEffect(() => {
		const el = forecastContainer
		if (!el) return

		const parent = el.parentElement as HTMLElement
		let fontSize = 12
		while (fontSize > 6 && el.offsetTop + el.offsetHeight > parent.offsetTop + parent.offsetHeight) {
			fontSize *= 0.9
			el.style.fontSize = fontSize + "pt"
		}
	}, [dimensions, forecastContainer, forecast])

	const { issue_time, discussion } = forecast
	const { confidence, periods } = discussion

	return (
		<div ref={setForecastContainer} style={{ fontSize: "12pt" }}>
			<p>
				Forecast posted {formatDate(new Date(issue_time), "HH:mm")}. Confidence {confidence.toLocaleLowerCase()}.
			</p>
			,
			<table style={{ borderCollapse: "collapse", margin: "auto" }}>
				<tbody>
					{periods?.map(({ period, description }, i) => {
						const [weekday, modifier] = period.split(" ")
						return (
							<tr key={period} style={{ backgroundColor: i % 2 ? "#ddd" : "#fff" }}>
								<th>
									{weekday.substring(0, 2)}
									{modifier === "night" ? "2" : "1"}
								</th>
								<td>{description}</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
